<script setup>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'
import AreasService from '@/services/AreaService.js'

const area = ref({ name: '', description: '' })
const route = useRoute()
const id = route.params.id
const router = useRouter()
const loading = ref(false)

const loadData = async () => {
  const resp = await AreasService.get(id)
  area.value = resp.area
}
if (id) {
  loadData()
}

// validation rules for Vuelidate
const shortName = helpers.regex(/^[a-zA-Z0-9-]*$/)
const rules = computed(() => ({
  area: {
    name: {
      required,
      regex: helpers.withMessage('Caracteres no válidos', shortName),
      minLength: minLength(3),
      maxLength: maxLength(10)
    },
    description: {
      required
    }
  }
}))

const v$ = useVuelidate(rules, { area }, { $autoDirty: true })

const submit = async () => {
  const validForm = await v$.value.$validate()
  if (!validForm) {
    return
  }
  loading.value = true
  try {
    if (id) {
      await AreasService.update(area.value)
    } else {
      await AreasService.create(area.value)
    }
    router.push({ name: 'AreaList' })
  } catch {
    loading.value = false
  }
}
</script>

<template>
  <Card v-if="area">
    <template #title>Area {{ area.name }}</template>
    <template #content>
      <form ref="form" @submit.prevent="submit">
        <div class="formgrid grid">
          <div class="field col">
            <label for="name">Nombre:</label>
            <InputText
              id="name"
              v-model="area.name"
              placeholder="Nombre corto"
              :invalid="v$.area.name.$error"
            />
            <span v-if="v$.area.name.$error" class="p-error">
              {{ v$.area.name.$errors[0].$message }}
            </span>
          </div>

          <div class="field col">
            <label for="descripcion">Descripción:</label>
            <Textarea
              id="descripcion"
              v-model.trim="area.description"
              :invalid="v$.area.description.$error"
              rows="4"
              cols="40"
            />
            <span v-if="v$.area.description.$error" class="p-error">
              {{ v$.area.description.$errors[0].$message }}
            </span>
          </div>
        </div>
        <Button
          type="submit"
          label="Guardar"
          :loading="loading"
          :disabled="v$.$error"
        />
      </form>
    </template>
  </Card>
  <div v-else>
    <p>Cargando datos...</p>
    <ProgressSpinner />
  </div>
</template>
