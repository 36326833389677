import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const username = ref(null)
  const perms = ref(null)

  function login(username_, permissions) {
    username.value = username_
    perms.value = permissions
  }

  function logout() {
    username.value = null
    perms.value = null
  }

  return { username, perms, login, logout }
})
