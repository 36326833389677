<script setup>
// This component represents a row in the form to edit data series
import { computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const model = defineModel({
  type: Object,
  required: true
}) // v-model bidirectional bind
defineEmits(['delete'])

// validation rules for Vuelidate (parent Vuelidate object to receive nested errors)
const rules = computed(() => ({
  year: {
    required
  },
  v: {
    required
  }
}))

const v$ = useVuelidate(rules, model, { $autoDirty: true })
</script>

<template>
  <div class="field">
    <InputNumber
      id="year"
      v-model="model.year"
      locale="es-ES"
      :show-buttons="true"
      :use-grouping="false"
      type="number"
      autofocus
      :invalid="v$.year.$error"
    />

    <!--  @input="model.year = $event.value" -->
    <!-- see issue https://github.com/primefaces/primevue/issues/506 -->
    <span
      v-for="(error, errIdx) in v$.year.$errors"
      :key="errIdx"
      class="p-error"
    >
      {{ error.$message }}
    </span>
  </div>

  <div class="field">
    <InputNumber
      id="value"
      v-model="model.v"
      locale="es-ES"
      :max-fraction-digits="3"
      :invalid="v$.v.$error"
    />
    <span v-for="(error, errIdx) in v$.v.$errors" :key="errIdx" class="p-error">
      {{ error.$message }}
    </span>
  </div>

  <Button
    icon="pi pi-times-circle"
    severity="secondary"
    class="mb-3"
    @click="$emit('delete', $event)"
  />
</template>
