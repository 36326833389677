<script setup>
import { useUserStore } from '@/stores/user.js'
import { RouterView } from 'vue-router'
import AppTopbar from './AppTopbar.vue'

const store = useUserStore()

// State synchronization with localStorage. It can also be done by using a
// plugin like "pinia-plugin-persistedstate" but can be implemented easily with
// Pinia's subscribtions and localStorage's events.

/** Save state to localStorage on any state mutation in Pinia store */
store.$subscribe((_ /*mutation*/, state) => {
  localStorage.setItem('userStore', JSON.stringify(state))
  console.log('estado guardado')
})

/** Load state from localStorage to Pinia store if there is anything saved */
function loadState() {
  const storageData = localStorage.getItem('userStore')
  console.log('Searching previous state in localStorage')
  if (storageData) {
    const state = JSON.parse(storageData)
    console.log('State found in localStorage, loading:', state)
    store.$state = state
  }
}

// load state if it exists on start of application or page reload
loadState()

// load state whenever data localStorage changes from another window or tab
window.addEventListener('storage', (event) => {
  if (event.key === 'userStore') {
    loadState()
  }
})
</script>

<template>
  <header>
    <app-topbar></app-topbar>
  </header>

  <!-- sidebar, if needed -->

  <main class="flex-1 m-auto w-11 py-3">
    <RouterView />
  </main>

  <footer class="surface-600 text-200 p-4">
    <span class="mr-4">EECAM - 2024. Versión 0.1</span
    ><RouterLink to="/about">Sobre esta aplicación</RouterLink>
  </footer>

  <Toast />
</template>

<style scoped>
/*
.container {
  display: flex;
  align-items: center;
  justify-content: center;
} */
</style>
