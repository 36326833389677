<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user.js'
import IndicatorService from '@/services/IndicatorService.js'
import Serie from '@/components/Serie.vue'

const route = useRoute()
const store = useUserStore()
const slug = ref(route.params.slug)
const indicador = ref()

const loadData = async () => {
  const response = await IndicatorService.get(slug.value)
  if (response.indicador) {
    indicador.value = response.indicador
  }
}

onMounted(() => {
  loadData()
})

const canEdit = () => {
  return (
    store.perms.values_edit ||
    store.username == indicador.value.provider.fullname
  )
}
</script>

<template>
  <div v-if="indicador">
    <h1>
      Indicador {{ indicador.estrategico ? 'estratégico' : 'secundario' }}
    </h1>
    <h2>
      {{ indicador.estrategico ? 'Estratégico' : 'Secundario' }}
      {{ indicador.name }}
    </h2>

    <dl class="dataset">
      <dt>Descripción</dt>
      <dd>{{ indicador.description }}</dd>
      <template v-if="indicador.estrategico">
        <dt>Objetivo estratégico</dt>
        <dd>
          <Chip :label="indicador.objetivo.name" class="mx-1" />
          {{ indicador.objetivo.description }}
        </dd>
      </template>
      <dt>Áreas</dt>
      <dd>
        <ul>
          <li v-for="(area, idx) in indicador.areas" :key="idx">
            <Chip :label="area.name" class="mx-1" />
            {{ area.description }}
          </li>
        </ul>
      </dd>
      <dt>Proveedor</dt>
      <dd>
        <router-link
          :to="{ name: 'UserDetail', params: { uid: indicador.provider_uid } }"
        >
          {{ indicador.provider.fullname }}
        </router-link>
      </dd>
      <dt>Fórmula</dt>
      <dd>{{ indicador.formula }}</dd>
      <dt>Unidades</dt>
      <dd>{{ indicador.units }}</dd>
      <dt>Valor base</dt>
      <dd>{{ indicador.base }} {{ indicador.units }}</dd>
    </dl>

    <div
      v-if="store.perms.metadata_edit"
      class="flex justify-content-end flex-wrap mt-3"
    >
      <div class="inline-block">
        <router-link
          :to="{ name: 'IndicatorUpdate', params: { slug: indicador.slug } }"
        >
          <Button label="Modificar" severity="primary" outlined />
        </router-link>
      </div>
    </div>

    <!-- Valores actuales del indicador -->
    <Serie
      v-if="indicador.values.length > 0"
      :description="indicador.name"
      :units="indicador.units"
      :values="indicador.values"
      class="mt-5"
    />
    <Message v-else severity="warn" :closable="false">
      El indicador no dispone de datos calculados. Revisa los datos disponibles.
    </Message>

    <!-- Series de datos del indicador -->
    <div v-for="serie in indicador.series" :key="serie.label">
      <Serie
        :label="serie.label"
        :description="serie.description"
        :units="serie.units"
        :values="serie.values"
        :slug="indicador.slug"
        class="mt-5"
      />
      <div v-if="canEdit()" class="flex justify-content-end flex-wrap mt-3">
        <div class="inline-block">
          <router-link
            :to="{
              name: 'IndicatorSerieData',
              params: { slug: indicador.slug, label: serie.label }
            }"
          >
            <Button label="Modificar valores" severity="primary" outlined />
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <p>Cargando datos...</p>
    <ProgressSpinner />
  </div>
</template>

<style scoped>
.graficos {
  width: 50%;
}
</style>
