<script setup>
import { ref, computed } from 'vue'
import AuthService from '/src/services/AuthService.js'
import { useRouter, useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'

const router = useRouter()
const route = useRoute()
const loading = ref(false)

const user = ref({
  username: '',
  email: '',
  firstname: '',
  lastname: '',
  active: true,
  admin: false,
  manager: false,
  editor: false
})
const uid = route.params.uid

async function loadUser() {
  const response = await AuthService.get(uid)
  user.value = response.user
}
if (uid) {
  loadUser(uid)
}

const submit = async () => {
  const validForm = await v$.value.$validate()
  if (!validForm) {
    return
  }
  loading.value = true
  try {
    if (uid) {
      await AuthService.update(user.value)
    } else {
      await AuthService.create(user.value)
    }
    router.push({ name: 'UserList' })
  } catch {
    loading.value = false
  }
}

// validation rules for Vuelidate
const rules = computed(() => ({
  user: {
    username: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(15)
    },
    firstname: {
      required
    },
    lastname: {
      required
    },
    email: {
      required,
      email
    }
  }
}))
const v$ = useVuelidate(rules, { user }, { $autoDirty: true })
</script>

<template>
  <div class="row">
    <div v-if="user" class="col-sm-6">
      <h1 class="my-4">Modifica cuenta de usuario</h1>
      <!-- <p>User ID: {{ user.uid }}</p> -->

      <form ref="form" @submit.prevent="submit">
        <div class="formgrid grid">
          <div class="field col">
            <label for="name">Nombre de usuario:</label>
            <InputText
              id="username"
              v-model.trim="user.username"
              placeholder="Nombre corto"
              :invalid="v$.user.username.$error"
            />
            <small>Nombre de login</small>
            <span v-if="v$.user.username.$error" class="p-error">
              {{ v$.user.username.$errors[0].$message }}
            </span>
          </div>

          <div class="field col">
            <label for="name">Email:</label>
            <InputText
              id="email"
              v-model.trim="user.email"
              :invalid="v$.user.email.$error"
            />
            <span v-if="v$.user.email.$error" class="p-error">
              {{ v$.user.email.$errors[0].$message }}
            </span>
          </div>

          <div class="field col">
            <label for="name">Nombre:</label>
            <InputText
              id="firstname"
              v-model.trim="user.firstname"
              placeholder="Nombre real"
              :invalid="v$.user.firstname.$error"
            />
            <span v-if="v$.user.firstname.$error" class="p-error">
              {{ v$.user.firstname.$errors[0].$message }}
            </span>
          </div>

          <div class="field col">
            <label for="name">Apellidos:</label>
            <InputText
              id="lastname"
              v-model.trim="user.lastname"
              :invalid="v$.user.lastname.$error"
            />
            <span v-if="v$.user.lastname.$error" class="p-error">
              {{ v$.user.lastname.$errors[0].$message }}
            </span>
          </div>

          <div class="field col">
            <label for="name">Usuario activo</label>
            <InputSwitch id="active" v-model.trim="user.active" />
            <small
              >Los usuarios inactivos no podrán acceder a la aplicación</small
            >
          </div>
        </div>

        <div class="flex flex-wrap justify-content-center gap-3">
          <div class="flex align-items-center">
            <Checkbox
              v-model="user.admin"
              input-id="admin"
              name="admin"
              :binary="true"
            />
            <label for="admin" class="ml-2">Admin</label>
          </div>
          <div class="flex align-items-center">
            <Checkbox
              v-model="user.manager"
              input-id="manager"
              name="manager"
              :binary="true"
            />
            <label for="manager" class="ml-2">Manager</label>
          </div>
          <div class="flex align-items-center">
            <Checkbox
              v-model="user.editor"
              input-id="editor"
              name="editor"
              :binary="true"
            />
            <label for="editor" class="ml-2">Editor</label>
          </div>
        </div>

        <div class="mt-3">
          <Button
            type="submit"
            label="Guardar"
            :loading="loading"
            :disabled="v$.$error"
          />
        </div>
      </form>
    </div>
    <div v-else class="col-sm-10 mt-4">
      <p>No user available</p>
    </div>
  </div>
</template>

<style scoped></style>
