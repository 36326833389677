<script setup>
import { ref, watchEffect } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useUserStore } from '@/stores/user.js'
import ObjetivoService from '@/services/ObjetivoService.js'

const objetivos = ref(null)
const store = useUserStore()

watchEffect(async () => {
  const response = await ObjetivoService.list()
  objetivos.value = response.objetivos
})

// Diálogo de confirmación de borrado
const toast = useToast()
const confirm = useConfirm()
const confirm1 = (event, objetivo) => {
  confirm.require({
    target: event.currentTarget,
    message: '¿Seguro que quieres borrar este objetivo?',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Cancelar',
    acceptLabel: 'Borrar',
    accept: async () => {
      await ObjetivoService.delete(objetivo)
      let index = objetivos.value.indexOf(objetivo)
      if (index > -1) {
        objetivos.value.splice(index, 1)
      }
      toast.add({
        severity: 'info',
        summary: 'Confirmado',
        detail: `El objetivo ${objetivo.name} ha sido eliminado`,
        life: 3000
      })
    }
  })
}
</script>

<template>
  <ConfirmPopup></ConfirmPopup>
  <div v-if="objetivos">
    <h2>Objetivos estratégicos</h2>
    <DataTable
      :value="objetivos"
      :paginator="objetivos.length > 10"
      :rows="10"
      :rows-per-page-options="[5, 10, 20, 50]"
      table-style="min-width: 50rem"
    >
      <Column field="name" header="Nombre"></Column>
      <Column field="description" header="Descripción" />
      <Column field="description" header="Indicadores">
        <template #body="slotProps">
          <Chip
            v-for="(ind, i) in slotProps.data.indicadores"
            :key="i"
            class="m-1"
          >
            <router-link
              :to="{
                name: 'IndicatorDetail',
                params: { slug: ind.slug }
              }"
              >{{ ind.name }}</router-link
            >
          </Chip>
        </template>
      </Column>
      <Column v-if="store.perms.metadata_edit" header="Acciones">
        <template #body="slotProps">
          <span class="white-space-nowrap">
            <router-link
              :to="{
                name: 'ObjetivoUpdate',
                params: { id: slotProps.data.id }
              }"
            >
              <Button icon="pi pi-pencil" class="mr-2" severity="secondary" />
            </router-link>
            <Button
              v-if="slotProps.data.indicadores.length == 0"
              icon="pi pi-times-circle"
              severity="secondary"
              @click="confirm1($event, slotProps.data)"
            />
          </span>
        </template>
      </Column>
    </DataTable>
  </div>
  <div v-else>
    <p>Cargando datos...</p>
    <ProgressSpinner />
  </div>
</template>

<style></style>
