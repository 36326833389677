<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import AuthService from '/src/services/AuthService.js'

const email = ref('')
const username = ref(null)
const router = useRouter()
const toast = useToast()

async function submit() {
  await AuthService.passwordRequest(email.value)
  toast.add({
    severity: 'info',
    summary: 'Recuperación de contraseña',
    detail: 'Comprueba tu email para continuar con el cambio de contraseña',
    life: 5000
  })
  router.push({ name: 'home' })
}

onMounted(() => {
  username.value.focus()
})
</script>

<template>
  <!-- Based in https://github.com/firebase007/JWT_VUE_APP/blob/master/client/src/components/auth/login.vue -->
  <div class="row">
    <div class="col-sm-6 offset-sm-3 mt-3">
      <form @submit.prevent="submit">
        <div class="mb-3">
          <!-- <label for="username" class="form-label">User name:</label> -->
          Enter your email address to request a password change.
        </div>
        <label for="email" class="form-label">Email address:</label>
        <input
          id="email"
          ref="username"
          v-model="email"
          type="email"
          class="form-control mb-2"
        />
        <button class="btn btn-primary btn-block w-45 mt-3" type="submit">
          Request password change
        </button>
      </form>
    </div>
  </div>
</template>

<style scoped>
form {
  margin-top: 30px;
  /*height:auto; */
  /*padding-top:100px !important; */
}
</style>
