<script setup>
import { ref } from 'vue'

const props = defineProps({
  indicador: {
    type: Object,
    required: true
  }
})

// data for chart
const lineData = ref(null)
const lineOptions = ref(null)

// options for chart
let documentStyle = getComputedStyle(document.documentElement)
let textColor = documentStyle.getPropertyValue('--text-color')
let textColorSecondary = documentStyle.getPropertyValue(
  '--text-color-secondary'
)
let surfaceBorder = documentStyle.getPropertyValue('--surface-border')

// Update chart's data and options using the indicador's data series
// (see chartjs for structure of these data)
const setChart = () => {
  lineData.value = {
    labels: props.indicador.values.map((row) => row.year),
    datasets: [
      {
        label: props.indicador.label,
        data: props.indicador.values.map((row) => row.v),
        fill: false,
        backgroundColor: documentStyle.getPropertyValue('--primary-500'),
        borderColor: documentStyle.getPropertyValue('--primary-500'),
        tension: 0.4,
        spanGaps: true
      }
    ]
  }

  lineOptions.value = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          fontColor: textColor
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder,
          drawBorder: false
        }
      }
    }
  }
}
setChart()
</script>

<template>
  <Card>
    <template #title>
      {{ indicador.name }}
    </template>
    <template #content>
      <!-- progress bar -->
      <div class="mb-2 md:mt-0 flex align-items-center">
        <div
          class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
          style="height: 8px"
        >
          <div class="bg-orange-500 h-full" style="width: 50%"></div>
        </div>
        <span class="text-orange-500 ml-3 font-medium">%50</span>
      </div>

      <p>{{ indicador.description }}</p>

      <Chart type="line" :data="lineData" :options="lineOptions" />
    </template>
  </Card>
</template>
