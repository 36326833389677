<script setup>
import { ref, computed } from 'vue'
import { useUserStore } from '@/stores/user.js'
import IndicatorCard from '@/components/IndicatorCard.vue'
import IndicatorService from '@/services/IndicatorService.js'

const store = useUserStore()
const indicadores = ref([])

const load = async () => {
  if (store.username) {
    // utiliza la API privada (incluye todos los indicadores)
    const response = await IndicatorService.list()
    indicadores.value = response.indicadores
  } else {
    // utiliza la API pública (lista solo indicadores estratégicos)
    const response = await IndicatorService.list_public()
    indicadores.value = response
  }
}
load()

const estrategicos = computed(() =>
  indicadores.value.filter((ind) => ind.estrategico)
)
const noEstrategicos = computed(() =>
  indicadores.value.filter((ind) => !ind.estrategico)
)
const indPropios = computed(() =>
  indicadores.value.filter((ind) => ind.provider?.username == store.username)
)
</script>

<template>
  <main>
    <h1>EECAM</h1>
    <h2>
      Estrategia de Energía, Clima y Aire de la Comunidad de Madrid 2023-2030.
    </h2>

    <div v-if="store.username">
      <p>
        Has iniciado sesión como usuario <b>{{ store.username }}</b
        >.
      </p>
      <div v-if="indPropios.length > 0">
        <h2>Mis indicadores</h2>
        <p>
          Estos son los indicadores que están asociados a tu usuario. En cada
          uno de ellos encontrarás las diferentes series de datos que puedes
          actualizar.
        </p>
        <DataTable :value="indPropios">
          <Column field="name" header="Nombre">
            <template #body="slotProps">
              <router-link
                :to="{
                  name: 'IndicatorDetail',
                  params: { slug: slotProps.data.slug }
                }"
                >{{ slotProps.data.name }}</router-link
              >
            </template>
          </Column>
          <Column field="description" header="Descripción" />
        </DataTable>
      </div>
      <div v-else>
        <p>No tienes ningún indicador asociado a tu usuario.</p>
      </div>
    </div>

    <div v-if="indicadores">
      <div v-if="estrategicos.length > 0">
        <h2>Indicadores estratégicos</h2>
        <div class="flex">
          <DataView :value="estrategicos" layout="grid">
            <template #grid="slotProps">
              <div class="grid grid-nogutter align-items-stretch">
                <div
                  v-for="(indicador, index) in slotProps.items"
                  :key="index"
                  class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-3 p-2"
                >
                  <IndicatorCard :indicador="indicador" class="h-full" />
                </div>
              </div>
            </template>
          </DataView>
        </div>
      </div>

      <div v-if="noEstrategicos.length > 0">
        <h2>Indicadores no estratégicos</h2>
        <div class="flex">
          <DataView :value="noEstrategicos" layout="grid">
            <template #grid="slotProps">
              <div class="grid grid-nogutter align-items-stretch">
                <div
                  v-for="(indicador, index) in slotProps.items"
                  :key="index"
                  class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-3 p-2"
                >
                  <IndicatorCard :indicador="indicador" class="h-full" />
                </div>
              </div>
            </template>
          </DataView>
        </div>
      </div>
    </div>

    <div v-else>
      <p>Cargando indicadores...</p>
      <ProgressSpinner />
    </div>
  </main>
</template>
