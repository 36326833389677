<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import AuthService from '/src/services/AuthService.js'

const router = useRouter()
const route = useRoute()
const uid = ref(route.params.uid)
const user = ref({})
const toast = useToast()

getUserData()

async function getUserData() {
  const response = await AuthService.get(uid.value)
  Object.assign(user.value, response.user)
}

const confirm = useConfirm()
const confirmDelete = (event) => {
  confirm.require({
    target: event.currentTarget,
    message:
      '¿Seguro que quieres borrar este usuario?. Solo será posible si' +
      ' no tiene asignado ningún indicador. También puedes desactivarlo.',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Cancelar',
    acceptLabel: 'Borrar',
    accept: async () => {
      await AuthService.delete(user.value.uid)
      toast.add({
        severity: 'info',
        summary: `Usuario borrado`,
        detail: 'El usuario ' + user.value.username,
        life: 5000
      })
      router.push({ name: 'UserList' })
    }
  })
}
</script>

<template>
  <ConfirmPopup class="max-w-30rem"></ConfirmPopup>
  <h1 class="my-4">Detalles de usuario</h1>
  <h2 class="my-4">{{ user.username }}</h2>

  <dl class="dataset">
    <dt>User uid:</dt>
    <dd>{{ user.uid }}</dd>
    <dt>User name:</dt>
    <dd>{{ user.username }}</dd>
    <dt>First name:</dt>
    <dd>{{ user.firstname }}</dd>
    <dt>Last name:</dt>
    <dd>{{ user.lastname }}</dd>
    <dt>email:</dt>
    <dd>
      <a :href="'mailto:' + user.email">{{ user.email }}</a>
    </dd>
    <dt>Active:</dt>
    <dd>
      <i class="pi" :class="user.active ? 'pi-check' : 'pi-times'"></i>
    </dd>

    <dt>Notify:</dt>
    <dd>
      <i class="pi" :class="user.notify ? 'pi-check' : 'pi-times'"></i>
    </dd>
    <dt>Created:</dt>
    <dd>{{ user.created }}</dd>
    <dt>Last login:</dt>
    <dd>{{ user.last_login }}</dd>
    <dt>Editor:</dt>
    <dd>
      <i class="pi" :class="user.editor ? 'pi-check' : 'pi-times'"></i>
    </dd>
    <dt>Manager:</dt>
    <dd>
      <i class="pi" :class="user.manager ? 'pi-check' : 'pi-times'"></i>
    </dd>
    <dt>admin:</dt>
    <dd>
      <i class="pi" :class="user.admin ? 'pi-check' : 'pi-times'"></i>
    </dd>
  </dl>
  <div class="flex gap-3">
    <RouterLink :to="{ name: 'UserUpdate' }">
      <Button label="Modificar" link
    /></RouterLink>
    <Button label="Borrar" severity="danger" outlined @click="confirmDelete" />
  </div>
</template>

<style scoped></style>
