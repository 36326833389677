<script setup>
import { ref, watchEffect } from 'vue'
import { useUserStore } from '@/stores/user.js'

const store = useUserStore()

// Users menu
const menu = ref(null)
const items = ref([])

watchEffect(() => {
  // When there is a uses logged in
  if (store.username) {
    items.value = []
    // TODO: esto solo si es administrador: obtener ese dato del store
    let admin = true
    if (admin) {
      items.value.push({
        label: 'Administración',
        items: [
          {
            label: 'Lista de usuarios',
            icon: 'pi pi-at',
            route: { name: 'UserList' }
          }
        ]
      })
    }
    items.value.push({
      label: `Perfil (${store.username})`,
      items: [
        {
          label: `Configuración`,
          icon: 'pi pi-user-edit',
          route: { name: 'UserProfile' }
        },

        {
          label: 'Salir (logout)',
          icon: 'pi pi-sign-out',
          route: { name: 'logout' }
        }
      ]
    })
  } else {
    items.value = [
      {
        label: 'Acceder (login)',
        icon: 'pi pi-sign-in',
        route: { name: 'login' }
      }
    ]
  }
})

const toggle = (event) => {
  menu.value.toggle(event)
}
</script>

<template>
  <Toolbar class="">
    <template #start>
      <router-link :to="{ name: 'home' }">
        <Button label="EECAM" text plain />
      </router-link>
      <template v-if="store.username">
        <router-link :to="{ name: 'indicator-list' }">
          <Button label="Indicadores" text plain />
        </router-link>
        <router-link :to="{ name: 'AreaList' }">
          <Button label="Áreas" text plain />
        </router-link>
        <router-link :to="{ name: 'ObjetivoList' }">
          <Button label="Objetivos" text plain />
        </router-link>
        <router-link :to="{ name: 'SerieList' }">
          <Button label="Series" text plain />
        </router-link>
      </template>
    </template>

    <template #center> </template>

    <template #end>
      <span v-if="store.username" class="mr-2">{{ store.username }}</span>
      <Button
        type="button"
        icon="pi pi-user"
        severity="secondary"
        rounded
        aria-haspopup="true"
        aria-controls="overlay_menu"
        @click="toggle"
      />
      <Menu id="overlay_menu" ref="menu" :model="items" :popup="true">
        <template #item="{ item, props }">
          <router-link v-slot="{ href, navigate }" :to="item.route" custom>
            <a v-ripple :href="href" v-bind="props.action" @click="navigate">
              <span :class="item.icon" />
              <span class="ml-2">{{ item.label }}</span>
            </a>
          </router-link>
        </template>
      </Menu>
    </template>
  </Toolbar>
</template>

<style scoped></style>
