<script setup>
import { ref, computed, onMounted } from 'vue'
import { useUserStore } from '@/stores/user.js'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import AuthService from '/src/services/AuthService.js'

const form = ref({
  username: '',
  password: ''
})
const router = useRouter()
const store = useUserStore()
const toast = useToast()
const loading = ref(false)
const username = ref(null)

// validation rules for Vuelidate
const rules = computed(() => ({
  form: {
    username: {
      required,
      $autoDirty: true
    },
    password: {
      required,
      $autoDirty: true
    }
  }
}))
const v$ = useVuelidate(rules, { form })

const submit = async () => {
  const validForm = await v$.value.$validate()
  if (!validForm) {
    return
  }
  loading.value = true
  try {
    const response = await AuthService.login(
      form.value.username,
      form.value.password
    )
    store.login(form.value.username, response.perms)
    toast.add({
      severity: 'info',
      summary: 'Logged in',
      detail: 'Bienvenido/a ' + form.value.username,
      life: 5000
    })
    router.push({ name: 'home' })
  } catch {
    loading.value = false
  }
}

onMounted(() => {
  username.value.$el.focus()
})
</script>

<template>
  <form @submit.prevent="submit">
    <div class="field">
      <label for="name">Nombre de usuario:</label>
      <InputText
        id="username"
        ref="username"
        v-model.trim="form.username"
        type="text"
        autofocus
        :invalid="v$.form.username.$error"
      />
      <span v-if="v$.form.username.$error" class="p-error">
        {{ v$.form.username.$errors[0].$message }}
      </span>
    </div>

    <div class="field">
      <label for="name">Contraseña:</label>
      <Password
        id="password"
        v-model.trim="form.password"
        toggle-mask
        :feedback="false"
        :invalid="v$.form.password.$error"
      />
      <span v-if="v$.form.password.$error" class="p-error">
        {{ v$.form.password.$errors[0].$message }}
      </span>
    </div>

    <div class="col-sm-6 mt-3">
      <Button
        type="submit"
        label="Acceder"
        :loading="loading"
        :disabled="v$.$error"
      />
    </div>
    <p>
      ¿Has olvidado tu contraseña o aún no tienes?<br />
      <RouterLink :to="{ name: 'PasswordRequest' }"
        >Establecer nueva contraseña</RouterLink
      >.
    </p>
  </form>
</template>

<style scoped></style>
