import { createRouter, createWebHistory } from 'vue-router'

import AreaUpdate from '../views/AreaUpdate.vue'
import AreasList from '../views/AreaList.vue'
import HomeView from '../views/HomeView.vue'
import IndicatorDetail from '../views/IndicatorDetail.vue'
import IndicatorUpdate from '../views/IndicatorUpdate.vue'
import ObjetivoList from '../views/ObjetivoList.vue'
import ObjetivoUpdate from '../views/ObjetivoUpdate.vue'
import PasswordRequest from '../components/auth/PasswordRequest.vue'
import PasswordReset from '../components/auth/PasswordReset.vue'
import SerieList from '../views/SerieList.vue'
import SerieUpdate from '../views/SerieUpdate.vue'
import SerieData from '../views/SerieData.vue'
import UserDetail from '../components/auth/UserDetail.vue'
import UserList from '../components/auth/UserList.vue'
import UserLogin from '../components/auth/UserLogin.vue'
import UserLogout from '../components/auth/UserLogout.vue'
import UserProfile from '../components/auth/UserProfile.vue'
import UserUpdate from '../components/auth/UserUpdate.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', name: 'home', component: HomeView },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    },

    // Areas
    { path: '/areas/', name: 'AreaList', component: AreasList },
    { path: '/areas/create', name: 'AreaCreate', component: AreaUpdate },
    { path: '/areas/:id/update', name: 'AreaUpdate', component: AreaUpdate },

    // Objetivos
    { path: '/objetivos/', name: 'ObjetivoList', component: ObjetivoList },
    {
      path: '/objetivos/:id/update',
      name: 'ObjetivoUpdate',
      component: ObjetivoUpdate
    },

    // Series
    { path: '/series/', name: 'SerieList', component: SerieList },
    {
      path: '/series/:id/update',
      name: 'SerieUpdate',
      component: SerieUpdate
    },
    {
      path: '/series/create',
      name: 'SerieCreate',
      component: SerieUpdate
    },
    {
      path: '/series/:id/data',
      name: 'SerieData',
      component: SerieData
    },

    // Indicadores
    {
      path: '/indicator/',
      name: 'indicator-list',
      component: () => import('../views/IndicatorList.vue')
    },
    {
      path: '/indicator/:slug/',
      name: 'IndicatorDetail',
      component: IndicatorDetail
    },
    {
      path: '/indicator/:slug/update',
      name: 'IndicatorUpdate',
      component: IndicatorUpdate
    },
    {
      path: '/indicator/:slug/serie/:label/data',
      name: 'IndicatorSerieData',
      component: SerieData
    },

    // Usuarios
    { path: '/auth/login', name: 'login', component: UserLogin },
    { path: '/auth/login', name: 'logout', component: UserLogout },
    { path: '/auth/user/', name: 'UserList', component: UserList },
    { path: '/auth/user/create', name: 'UserCreate', component: UserUpdate },
    { path: '/auth/profile', name: 'UserProfile', component: UserProfile },
    {
      path: '/auth/password-request',
      name: 'PasswordRequest',
      component: PasswordRequest
    },
    {
      path: '/auth/password-reset/:token/',
      name: 'PasswordReset',
      component: PasswordReset
    },
    {
      path: '/auth/user/:uid/update',
      name: 'UserUpdate',
      component: UserUpdate
    },
    { path: '/auth/user/:uid/', name: 'UserDetail', component: UserDetail }
  ]
})

export default router
