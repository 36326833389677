<script setup>
import { ref, watchEffect } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useUserStore } from '@/stores/user.js'
import SerieService from '@/services/SerieService.js'

const series = ref(null)
const store = useUserStore()

watchEffect(async () => {
  const response = await SerieService.list()
  series.value = response.series
})

// Diálogo de confirmación de borrado
const toast = useToast()
const confirm = useConfirm()
const confirm1 = (event, serie) => {
  confirm.require({
    target: event.currentTarget,
    message: '¿Seguro que quieres borrar esta serie?',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Cancelar',
    acceptLabel: 'Borrar',
    accept: async () => {
      await SerieService.delete(serie)
      let index = series.value.indexOf(serie)
      if (index > -1) {
        series.value.splice(index, 1)
      }
      toast.add({
        severity: 'info',
        summary: 'Confirmado',
        detail: `La serie ${serie.name} ha sido eliminada`,
        life: 3000
      })
    }
  })
}
</script>

<template>
  <ConfirmPopup></ConfirmPopup>
  <div v-if="series">
    <h2>Series de datos compartidas</h2>
    <p>
      Las siguientes series de datos son de uso compartido y están disponibles
      para su uso desde cualquier indicador. Se pueden referenciar desde la
      fórmula de cálculo de cada indicador mediante su etiqueta.
    </p>
    <DataTable
      :value="series"
      :paginator="series.length > 10"
      :rows="10"
      :rows-per-page-options="[5, 10, 20, 50]"
      table-style="min-width: 50rem"
    >
      <Column field="name" header="Nombre" />
      <Column field="description" header="Descripción" />
      <Column v-if="store.perms.metadata_edit" header="Acciones">
        <template #body="slotProps">
          <span class="white-space-nowrap">
            <router-link
              :to="{ name: 'SerieUpdate', params: { id: slotProps.data.id } }"
            >
              <Button icon="pi pi-pencil" class="mr-2" severity="secondary" />
            </router-link>
            <router-link
              :to="{ name: 'SerieData', params: { id: slotProps.data.id } }"
            >
              <Button
                icon="pi pi-chart-bar"
                class="mr-2"
                severity="secondary"
              />
            </router-link>
            <Button
              icon="pi pi-times-circle"
              severity="secondary"
              @click="confirm1($event, slotProps.data)"
            />
          </span>
        </template>
      </Column>
    </DataTable>
    <router-link v-if="store.perms.metadata_edit" :to="{ name: 'SerieCreate' }">
      <Button label="Crear Serie" class="mt-3 mr-3" />
    </router-link>
  </div>
  <div v-else>
    <p>Cargando datos...</p>
    <ProgressSpinner />
  </div>
</template>

<style></style>
