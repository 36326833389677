import { api } from '/src/services/api.js'

export default {
  async login(username, password) {
    // Credentials can be passed in JSON body or as headers.
    // Here it uses standar authorization headers (like in plain HTTP auth)
    // Authorization header = Basic + base64(username:password)
    let cred = btoa(username + ':' + password)

    // Using axios library.
    //  let response = await axios.post("http://localhost:5000/auth/login",
    // let response = await axios.post("/api/auth/login",
    //                                 {'prueba1': 'prueba2'},
    //                                 { headers: {'Authorization': `Basic ${cred}` } });
    // let token = response.data.token;
    // Storing in localStorage, not very secure.
    // localStorage.setItem("user", token);

    // Delegating api call to our own 'service' based on fetch
    let response = await api('/auth/login', {
      method: 'POST',
      headers: { Authorization: `Basic ${cred}` }
    })
    // This is the token from JSON response. But we have it set as an HTTP
    // cookieOnly also, so we don't need to store it in localStorage which
    // is not secure (other javascript libraries have access to
    // localStorage too).
    //   let token = response.token
    return response
  },

  async logout() {
    // If JWT token is stored in localStorage, remove it and send it to
    // api to be included in revocation list (using axios).
    // const jwt = localStorage.user;
    // localStorage.removeItem("user");
    // await axios.get(
    //   // 'http://localhost:5000/auth/logout',
    //   '/api/auth/logout',
    //   { 'headers': {'Authorization': `Bearer ${jwt}`} });

    // JWT token is in a httpOlny cookie. Only the server response can
    // delete the cookie. Also, calling logout the token is added to
    // revokation blacklist.
    await api('/auth/logout')
    // TODO: borrar la cookie que tiene el token mediante javascript por si falla la solicitud
  },

  list() {
    return api('/auth/user/')
  },

  get(uid) {
    return api(`/auth/user/${uid}/`)
  },

  create(user) {
    return api(`/auth/user/create`, {
      method: 'POST',
      body: JSON.stringify({ user })
    })
  },

  update(user) {
    return api(`/auth/user/${user.uid}/update`, {
      method: 'PUT',
      body: JSON.stringify({ user })
    })
  },

  getProfile() {
    return api(`/auth/profile/`)
  },

  updateProfile(user) {
    return api(`/auth/profile/update`, {
      method: 'PUT',
      body: JSON.stringify({ user })
    })
  },

  delete(uid) {
    return api(`/auth/user/${uid}/`, {
      method: 'DELETE'
    })
  },

  passwordRequest(email) {
    return api('/auth/password-request?email=' + encodeURIComponent(email))
  },

  passwordReset(password, token) {
    return api('/auth/password-reset', {
      method: 'POST',
      body: JSON.stringify({ token, password })
    })
  }
}
