<script setup>
import { useUserStore } from '@/stores/user.js'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import AuthService from '/src/services/AuthService.js'

const router = useRouter()
const store = useUserStore()
const toast = useToast()

function logoutUser() {
  // don't wait for answer, ignore it
  AuthService.logout()
  // TODO this.$store.dispatch('logout');
  store.logout()
  toast.add({
    severity: 'info',
    summary: 'Sesión cerrada',
    detail: 'Se ha cerrado la sesión',
    life: 5000
  })
  router.push({ name: 'home' })
}
</script>

<template>
  <div class="container">
    <p class="mt-4">¿Deseas abandonar la sesión?</p>

    <Button label="Cerrar sesión" class="mt-3" @click="logoutUser" />
  </div>
</template>
