import { api } from '/src/services/api.js'

export default {
  list() {
    return api('/areas/')
  },

  create(area) {
    return api(`/areas/`, {
      method: 'POST',
      body: JSON.stringify({ area })
    })
  },

  get(id) {
    return api(`/areas/${id}/`)
  },

  update(area) {
    return api(`/areas/${area.id}/`, {
      method: 'PUT',
      body: JSON.stringify({ area })
    })
  },

  delete(area) {
    return api(`/areas/${area.id}/`, {
      method: 'DELETE'
    })
  }
}
