<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { useConfirm } from 'primevue/useconfirm'
import SerieService from '@/services/SerieService.js'
import IndicatorService from '@/services/IndicatorService.js'
import SerieDataRow from '@/components/SerieDataRow.vue'

const serie = ref({ name: '', description: '', link: '', url: '', values: [] })
const rows = ref([])
const route = useRoute()
const slug = route.params.slug
const label = route.params.label
const id = route.params.id
const router = useRouter()
const loading = ref(false)

const loadData = async () => {
  if (id) {
    const resp = await SerieService.get(id)
    serie.value = resp.serie
  } else {
    const resp = await IndicatorService.get(slug)
    serie.value = resp.indicador.series.filter((v) => v.label == label)[0]
  }
}

// fetch data while rendering to reduce latency
loadData()

const v$ = useVuelidate()

const submit = async () => {
  const validForm = await v$.value.$validate()
  if (!validForm) {
    return
  }
  loading.value = true
  try {
    if (id) {
      await SerieService.update_values(serie.value)
      router.push({ name: 'SerieList' })
    } else {
      await IndicatorService.update_values(slug, serie.value)
      router.push({ name: 'IndicatorDetail', params: slug })
    }
  } catch {
    loading.value = false
  }
}

const add_row = () => {
  serie.value.values.push({ year: null, v: null })
  // const elem = rowsRef.value[serie.value.values.length - 1]
}

const confirm = useConfirm()
const confirmDelete = (event, row) => {
  confirm.require({
    target: event.currentTarget,
    message: '¿Seguro que quieres borrar esta fila?',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Cancelar',
    acceptLabel: 'Borrar',
    accept: () => {
      let index = serie.value.values.indexOf(row)
      if (index > -1) {
        serie.value.values.splice(index, 1)
      }
    }
  })
}

const functionRef = (idx, el) => {
  console.log(el)
  rows.value[idx] = el
}
</script>

<template>
  <ConfirmPopup class="max-w-30rem"></ConfirmPopup>
  <div v-if="serie">
    <h2>Serie {{ serie.name }}</h2>
    <h3>{{ serie.description }}</h3>
    <p>
      Fuente de datos: <a :href="serie.url">{{ serie.link }}</a>
    </p>
    <form ref="form" @submit.prevent="submit">
      <div
        v-for="(row, idx) in serie.values"
        :key="idx"
        class="flex align-items-center gap-3"
      >
        <SerieDataRow
          :ref="(el) => functionRef(idx, el)"
          v-model="serie.values[idx]"
          @delete="confirmDelete($event, row)"
        />
      </div>

      <!--  :ref="(el) => (rows = el)" -->
      <Button
        label="Añadir fila"
        severity="secondary"
        outlined
        class="mr-3"
        @click="add_row()"
      />
      <Button type="submit" label="Guardar" :loading="loading" />
    </form>
  </div>
  <div v-else>
    <p>Loading data...</p>
  </div>
</template>
