<script setup>
import { ref } from 'vue'
import AuthService from '/src/services/AuthService.js'
import { useUserStore } from '@/stores/user.js'

const users = ref([])
const store = useUserStore()

async function getUserList() {
  const response = await AuthService.list()
  if (response.users) {
    users.value = response.users
  }
}

getUserList()
</script>

<template>
  <DataTable
    :value="users"
    paginator
    :rows="10"
    :rows-per-page-options="[5, 10, 20, 50]"
    table-style="min-width: 50rem"
  >
    <Column field="username" header="Nombre de usuario" sortable>
      <template #body="slotProps">
        <router-link
          :to="{ name: 'UserDetail', params: { uid: slotProps.data.uid } }"
          >{{ slotProps.data.username }}</router-link
        >
      </template>
    </Column>
    <Column field="lastname" header="Apellido" sortable />
    <Column field="firstname" header="Nombre" sortable />
    <Column field="email" header="Email" sortable />
    <Column field="active" header="Estado" sortable>
      <template #body="slotProps">
        <i v-if="slotProps.data.active" class="pi pi-check"></i>
        <i v-else class="pi pi-minus"></i>
      </template>
    </Column>
  </DataTable>
  <div class="flex mt-3">
    <router-link v-if="store.perms.users_edit" :to="{ name: 'UserCreate' }">
      <Button label="Crear usuario" />
    </router-link>
  </div>
</template>

<style scoped>
.status {
  min-width: 5em;
}
</style>
