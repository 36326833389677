import { api } from '/src/services/api.js'

export default {
  list_public() {
    return api('/public/indicadores/')
  },

  list() {
    return api('/indicadores/')
  },

  create(indicador) {
    return api(`/indicadores/`, {
      method: 'POST',
      body: JSON.stringify({ indicador })
    })
  },

  get(slug) {
    return api(`/indicadores/${slug}/`)
  },

  update(indicador) {
    return api(`/indicadores/${indicador.slug}/`, {
      method: 'PUT',
      body: JSON.stringify({ indicador })
    })
  },

  update_values(slug, serie) {
    return api(`/indicadores/${slug}/serie/${serie.label}`, {
      method: 'PUT',
      body: JSON.stringify({ serie })
    })
  },

  delete(indicador) {
    return api(`/indicadores/${indicador.slug}/`, {
      method: 'DELETE'
    })
  }
}
