<script setup>
import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import AuthService from '/src/services/AuthService.js'

const router = useRouter()
const route = useRoute()
const toast = useToast()
const token = ref(route.params.token)
const password = ref('')
const password2 = ref('')
const password2input = ref(null)

async function submit() {
  await AuthService.passwordReset(password.value, token.value)
  toast.add({
    severity: 'info',
    summary: 'Contraseña modificada',
    detail:
      'Tu contraseña se ha modificado. Ahora puedes utilizarla para acceder.',
    life: 5000
  })
  router.push({ name: 'login' })
}

// Update client form validation for password (both inputs must match)
// Can't use watchEffect because it is run before component is mounted and
// password2input doesn't exists yet.
watch(
  () => [password.value, password2.value],
  () => {
    password2input.value.setCustomValidity(
      password.value == password2.value ? '' : 'Password must be matching.'
    )
    console.log('updated validity', password2input.value.checkValidity())
  }
)
</script>

<template>
  <!-- Based in https://github.com/firebase007/JWT_VUE_APP/blob/master/client/src/components/auth/login.vue -->
  <div class="row">
    <div class="col-sm-6 offset-sm-3 mt-3">
      <form @submit.prevent="submit">
        <div class="mb-3">Enter your email address to reset your password.</div>
        <div class="col-sm-6 mt-3">
          <label for="password" class="form-label">New password:</label>
          <input
            id="password"
            v-model="password"
            type="password"
            class="form-control mb-3"
            placeholder="leave blank to not change"
          />
        </div>
        <div class="col-sm-6 mt-3">
          <label for="password" class="form-label">Repeat new password:</label>
          <input
            id="password2"
            ref="password2input"
            v-model="password2"
            type="password"
            class="form-control mb-3"
            placeholder="repeat password"
          />
        </div>
        <button class="btn btn-primary btn-block w-45 mt-3" type="submit">
          Change password
        </button>
      </form>
    </div>
  </div>
</template>

<style scoped>
form {
  margin-top: 30px;
  /*height:auto; */
  /*padding-top:100px !important; */
}
</style>
