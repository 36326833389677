import { api } from '/src/services/api.js'

export default {
  list() {
    return api('/objetivos/')
  },

  create(area) {
    return api(`/objetivos/`, {
      method: 'POST',
      body: JSON.stringify({ area })
    })
  },

  get(id) {
    return api(`/objetivos/${id}/`)
  },

  update(objetivo) {
    return api(`/objetivos/${objetivo.id}/`, {
      method: 'PUT',
      body: JSON.stringify({ objetivo })
    })
  },

  delete(objetivo) {
    return api(`/objetivos/${objetivo.id}/`, {
      method: 'DELETE'
    })
  }
}
