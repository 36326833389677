<script setup>
import { ref } from 'vue'

const props = defineProps({
  description: {
    type: String,
    required: true
  },
  values: {
    type: Array,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  units: {
    type: String,
    default: ''
  }
})

// data for chart
const lineData = ref(null)
const lineOptions = ref(null)

// options for chart
let documentStyle = getComputedStyle(document.documentElement)
let textColor = documentStyle.getPropertyValue('--text-color')
let textColorSecondary = documentStyle.getPropertyValue(
  '--text-color-secondary'
)
let surfaceBorder = documentStyle.getPropertyValue('--surface-border')

// Update chart's data and options using the indicador's data series
// (see chartjs for structure of these data)
const setChart = () => {
  lineData.value = {
    labels: props.values.map((row) => row.year),
    datasets: [
      {
        label: props.label,
        data: props.values.map((row) => row.v),
        fill: false,
        backgroundColor: documentStyle.getPropertyValue('--primary-500'),
        borderColor: documentStyle.getPropertyValue('--primary-500'),
        tension: 0.4,
        spanGaps: true
      }
    ]
  }

  lineOptions.value = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          fontColor: textColor
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder,
          drawBorder: false
        }
      }
    }
  }
}
setChart()
</script>

<template>
  <Card>
    <template #title>
      {{ description }}
    </template>
    <template #content>
      <div v-if="values.length > 0" class="flex">
        <div class="flex-auto">
          <p v-if="label">Etiqueta: {{ label }}</p>

          <!-- Tabla de valores -->
          <DataTable :value="values" table-style="min-width: 20rem">
            <Column field="year" header="Año"></Column>
            <Column field="v" :header="`Valor ${label} [${units}]`"></Column>
          </DataTable>
        </div>

        <!-- Gráfico de los valores -->
        <div class="flex-auto">
          <h5>
            {{ description }} <span v-if="label">({{ label }})</span>
          </h5>
          <Chart type="line" :data="lineData" :options="lineOptions" />
        </div>
      </div>
      <div v-else>
        <p>Etiqueta: {{ label || '-' }}</p>
        <p>Unidades: {{ units || '-' }}</p>
        <Message severity="warn" :closable="false">
          La serie no contiene datos.
        </Message>
      </div>
    </template>
  </Card>
</template>
