// import store from "../store.js";
// import { useToast } from 'primevue/usetoast'
import { toast } from '@/main'

// Using CORS to access other server for API
// export const BASE_URL = 'http://localhost:5000';

// Using the same server than client (that has a proxy to API server)
// (In development we setup Vite dev server to proxyx /api to localhost:5000
//   where Flask dev server is listening).
// Using same host and port avoids the need for CORS and simplify cookies as
// everything is in the same domain.
export const BASE_URL = '/api'

// const toast = useToast()

export async function api(url, params = {}) {
  params = Object.assign(
    {
      // mode: "cors",
      cache: 'no-cache'
    },
    params
  )

  params.headers = Object.assign(
    {
      // Authorization: `Bearer ${store.state.token}`,
      'Content-Type': 'application/json'
    },
    params.headers
  )

  let response
  try {
    response = response = await fetch(BASE_URL + url, params)
  } catch (e) {
    // Network error: can't get a response
    toast.add({
      severity: 'error',
      summary: `Network error`,
      detail: e,
      life: 5000
    })
    throw new Error('Network error:', e)
  }

  // HTTP errors
  if (!response.ok) {
    let json, error
    if (response.headers.get('content-type') == 'application/json') {
      json = (await response.json()) || {}
      error = json.error
    }
    // if (response.status == 401) {
    //   // 401 - unauthorized request
    //   // logout if necessary
    //   // await store.dispatch('logout');
    // }
    toast.add({
      severity: 'error',
      summary: 'HTTP error ' + response.status,
      detail: `${response.statusText}\n${response.error || ''}\n${error || ''}`,
      life: 5000
    })
    throw new Error(`HTTP error: ${response.status}: ${response.statusText}`)
  }

  // Parse JSON data from response's body
  let json
  try {
    json = (await response.json()) || {}
  } catch (e) {
    // Error parsing JSON format
    toast.add({
      severity: 'error',
      summary: `Error parsing JSON response`,
      detail: e,
      life: 5000
    })
    throw new Error('API error: ' + e)
  }

  // Response is ok (http 200) but API is sending an error
  if (json.error) {
    toast.add({
      severity: 'error',
      summary: 'API error',
      detail: json.error,
      life: 5000
    })
    throw new Error('API error: ' + json.error)
  }

  return json
}
