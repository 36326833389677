<script setup>
import { ref, watchEffect } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useUserStore } from '@/stores/user.js'
import AreasService from '@/services/AreaService.js'

const areas = ref(null)
const store = useUserStore()

watchEffect(async () => {
  const response = await AreasService.list()
  areas.value = response.areas
})

// Diálogo de confirmación de borrado
const toast = useToast()
const confirm = useConfirm()
const confirm1 = (event, area) => {
  confirm.require({
    target: event.currentTarget,
    message: '¿Seguro que quieres borrar este área?',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Cancelar',
    acceptLabel: 'Borrar',
    accept: async () => {
      await AreasService.delete(area)
      let index = areas.value.indexOf(area)
      if (index > -1) {
        areas.value.splice(index, 1)
      }
      toast.add({
        severity: 'info',
        summary: 'Confirmado',
        detail: `El área ${area.name} ha sido eliminada`,
        life: 3000
      })
    }
    // reject: () => {
    // }
  })
}
</script>

<template>
  <ConfirmPopup></ConfirmPopup>
  <div v-if="areas">
    <DataTable
      :value="areas"
      paginator
      :rows="10"
      :rows-per-page-options="[5, 10, 20, 50]"
      table-style="min-width: 50rem"
      :pt="{ transition: { leaveToClass: 'fade-leave-to' } }"
    >
      <Column field="name" header="Nombre" sortable />
      <!-- TODO: mostrar indicadores de cada área -->
      <Column field="description" header="Descripción" sortable />
      <Column field="indicadores" header="Indicadores">
        <template #body="slotProps">
          <Chip
            v-for="(ind, i) in slotProps.data.indicadores"
            :key="i"
            class="mx-1"
          >
            <router-link
              :to="{
                name: 'IndicatorDetail',
                params: { slug: ind.slug }
              }"
              >{{ ind.name }}</router-link
            >
          </Chip>
        </template>
      </Column>
      <Column v-if="store.perms.metadata_edit" header="Acciones">
        <template #body="slotProps">
          <span class="white-space-nowrap">
            <router-link
              :to="{ name: 'AreaUpdate', params: { id: slotProps.data.id } }"
            >
              <Button icon="pi pi-pencil" class="mr-2" severity="secondary" />
            </router-link>
            <Button
              v-if="slotProps.data.indicadores.length == 0"
              icon="pi pi-times-circle"
              severity="secondary"
              @click="confirm1($event, slotProps.data)"
            />
          </span>
        </template>
      </Column>
    </DataTable>
    <router-link v-if="store.perms.metadata_edit" :to="{ name: 'AreaCreate' }">
      <Button label="Crear Área" class="mt-3 mr-3" />
    </router-link>
  </div>
  <div v-else>
    <p>Cargando datos...</p>
    <ProgressSpinner />
  </div>
</template>

<style></style>
