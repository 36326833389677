<script setup>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  minLength,
  maxLength,
  url,
  helpers
} from '@vuelidate/validators'
import SerieService from '@/services/SerieService.js'

const serie = ref({ name: '', description: '', link: '', url: '' })
const route = useRoute()
const id = route.params.id
const router = useRouter()
const loading = ref(false)

const loadData = async () => {
  const resp = await SerieService.get(id)
  serie.value = resp.serie
}

if (id) {
  loadData()
}

// validation rules for Vuelidate
const shortName = helpers.regex(/^[a-zA-Z0-9-]*$/)
const rules = computed(() => ({
  serie: {
    name: {
      required,
      regex: helpers.withMessage('Caracteres no válidos', shortName),
      // isUnique: helpers.withMessage(
      //   'El nombre está en uso',
      //   helpers.withAsync(async (value) => {
      //     if (value === '') return true
      //     const res = await SerieService.get_name(value)
      //     return !res.result
      //   })
      // ),
      minLength: minLength(3),
      maxLength: maxLength(10),
      $autoDirty: true
    },
    description: {
      required,
      $autoDirty: true
    },
    link: {
      $autoDirty: true
    },
    url: {
      url,
      $autoDirty: true
    }
  }
}))

const v$ = useVuelidate(rules, { serie })

const submit = async () => {
  const validForm = await v$.value.$validate()
  if (!validForm) {
    return
  }
  loading.value = true
  try {
    if (id) {
      await SerieService.update(serie.value)
    } else {
      await SerieService.create(serie.value)
    }
    router.push({ name: 'SerieList' })
  } catch {
    loading.value = false
  }
}
</script>

<template>
  <Card v-if="serie">
    <template #title>Serie {{ serie.name }}</template>
    <template #content>
      <form ref="form" @submit.prevent="submit">
        <div class="formgrid grid">
          <div class="field col">
            <label for="name">Nombre:</label>
            <InputText
              id="name"
              v-model.trim="serie.name"
              placeholder="Nombre corto"
              :invalid="v$.serie.name.$error"
            />
            <span v-if="v$.serie.name.$error" class="p-error">
              {{ v$.serie.name.$errors[0].$message }}
            </span>
          </div>

          <div class="field col">
            <label for="descripcion">Descripción:</label>
            <Textarea
              id="descripcion"
              v-model.trim="serie.description"
              :invalid="v$.serie.description.$error"
              rows="4"
              cols="40"
            />
            <span v-if="v$.serie.description.$error" class="p-error">
              {{ v$.serie.description.$errors[0].$message }}
            </span>
          </div>

          <div class="field col">
            <label for="link">Enlace (texto):</label>
            <InputText
              id="link"
              v-model.trim="serie.link"
              :invalid="v$.serie.link.$error"
            />
            <small id="name-help">Texto que se muestra en el enlace</small>
            <span v-if="v$.serie.link.$error" class="p-error">
              {{ v$.serie.link.$errors[0].$message }}
            </span>
          </div>

          <div class="field col">
            <label for="url">Enlace (URL):</label>
            <InputText
              id="url"
              v-model.trim="serie.url"
              placeholder="https://..."
              :invalid="v$.serie.url.$error"
            />
            <small id="name-help">Dirección a la que apunta el enlace</small>
            <span v-if="v$.serie.url.$error" class="p-error">
              {{ v$.serie.url.$errors[0].$message }}
            </span>
          </div>
        </div>

        <Button
          type="submit"
          label="Guardar"
          :loading="loading"
          :disabled="v$.$error"
        />
      </form>
    </template>
  </Card>
  <div v-else>
    <p>Cargando datos...</p>
    <ProgressSpinner />
  </div>
</template>
