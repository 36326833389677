<script setup>
// This component is used for local validation of the collection of data series
// included in an indicator. (See Vuelidate nested validation).
import { computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, url, requiredIf } from '@vuelidate/validators'

const model = defineModel({ type: Object })
// Emited when delete button is clicked so parent component can delete this data
// (do not use a callback in Vue, better to notify parent with an event).
defineEmits(['delete'])

// validation rules for Vuelidate
// This rules only validate this form, but global errors in v$ are accumulated
// in parent vuelidate object, in its v$ var.
const rules = computed(() => ({
  label: {
    required,
    maxLength: maxLength(10)
  },
  description: {
    required
  },
  link: {},
  url: {
    requiredIf: requiredIf(model.value.link.length > 0),
    url
  }
}))

const v$ = useVuelidate(rules, model, { $autoDirty: true })
</script>

<template>
  <Card class="my-3">
    <template #title>Serie {{ model.label }}</template>
    <template #content>
      <!-- <span>Serie: {{ model.object_id }}</span> -->
      <div class="formgrid grid">
        <div class="field col">
          <label for="label">Nombre corto:</label>
          <InputText
            id="label"
            v-model="model.label"
            type="text"
            :invalid="v$.label.$error"
          />
          <small id="label-help"
            >Nombre de variable que se utilizará en las fórmulas</small
          >
          <span
            v-for="(error, errIdx) in v$.label.$errors"
            :key="errIdx"
            class="p-error"
          >
            {{ error.$message }}
          </span>
        </div>

        <div class="field col">
          <label for="description">Descripción:</label>
          <InputText
            id="description"
            v-model="model.description"
            type="text"
            :invalid="v$.description.$error"
          />
          <span
            v-for="(error, errIdx) in v$.description.$errors"
            :key="errIdx"
            class="p-error"
          >
            {{ error.$message }}
          </span>
        </div>

        <div class="field col">
          <label for="link">Enlace:</label>
          <InputText
            id="link"
            v-model="model.link"
            type="text"
            :invalid="v$.link.$error"
          />
          <small id="link-help">Texto del enlace</small>
          <span
            v-for="(error, errIdx) in v$.link.$errors"
            :key="errIdx"
            class="p-error"
          >
            {{ error.$message }}
          </span>
        </div>

        <div class="field col">
          <label for="url">URL:</label>
          <InputText
            id="url"
            v-model="model.url"
            type="text"
            :invalid="v$.url.$error"
          />
          <small id="url-help">Dirección a la que apunta el enlace</small>
          <span
            v-for="(error, errIdx) in v$.url.$errors"
            :key="errIdx"
            class="p-error"
          >
            {{ error.$message }}
          </span>
        </div>
      </div>

      <!-- Show values for the serie:
      <ul>
        <li v-for="(point, idj) in model.values" :key="idj">
          x: {{ point.year }}, y: {{ point.v }}
        </li>
      </ul> -->

      <!-- serie can't be deleted here, no access to series array from here.
           An event is emited to parent for it to perform deletion. -->
      <Button
        label="Borrar"
        severity="danger"
        outlined
        @click="$emit('delete', $event)"
      />
    </template>
  </Card>
</template>
