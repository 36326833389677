<script setup>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  numeric,
  helpers
} from '@vuelidate/validators'
import IndicatorService from '@/services/IndicatorService.js'
import IndicatorSerieUpdate from '@/components/IndicatorSerieUpdate.vue'
import { api } from '/src/services/api.js'

const route = useRoute()
const router = useRouter()
const toast = useToast()

const slug = ref(route.params.slug)
const indicador = ref()
const providers = ref()
const objetivos = ref()
const areas = ref()

const form = ref(null)
const loading = ref(false)

const loadData = () => {
  // Using promises instead iof async/await to not block
  // Load everything in parallel
  IndicatorService.get(slug.value).then((resp) => {
    indicador.value = resp.indicador
    // remove values, keep only the metadata
    delete indicador.value.values
    for (let serie of indicador.value.series) {
      delete serie.values
    }
  })
  // TODO: use a service, not URLs here
  api(`/objetivos/`).then((resp) => {
    objetivos.value = resp.objetivos
  })
  api(`/areas/`).then((resp) => {
    areas.value = resp.areas
  })
  api(`/users/`).then((resp) => {
    providers.value = resp.users
  })
}

// fetch data while rendering to reduce latency
loadData()

// validation rules for Vuelidate
// const shortNameValidator = (value) => value.match(/^[a-zA-Z0-9\-_]*$/)
const shortName = helpers.regex(/^[a-zA-Z0-9-]*$/)

const rules = computed(() => ({
  indicador: {
    name: {
      required,
      regex: helpers.withMessage('Caracteres no válidos', shortName),
      // regex: helpers.withMessage('Caracteres no válidos', shortNameValidator),
      minLength: minLength(3),
      maxLength: maxLength(10)
    },
    description: {
      required
    },
    provider_uid: {
      required
    },
    objetivo_id: {
      requiredIfEst: requiredIf(indicador.value && indicador.value.estrategico)
      // required,
    },
    areas_id: {
      requiredIfSec: requiredIf(indicador.value && !indicador.value.estrategico)
    },
    base: {
      numeric
    },
    units: {
      required
    },
    formula: {
      required
    }
  }
}))

const v$ = useVuelidate(rules, { indicador }, { $autoDirty: true })

const submit = async (event) => {
  event
  const validForm = await v$.value.$validate()
  if (!validForm) {
    console.log('Invalid form')
    return
  }
  console.log('The form is valid. Sending data.')
  loading.value = true
  try {
    const resp = await IndicatorService.update(indicador.value)
    if (resp.warning) {
      toast.add({
        severity: 'warning',
        summary: 'Imposible calcular indicador',
        detail: resp.warning,
        life: 5000
      })
    }
    router.push({ name: 'IndicatorDetail', params: slug.value })
  } finally {
    loading.value = false
  }
}

const addSerie = () => {
  indicador.value.series.push({
    object_id: crypto.randomUUID(),
    label: '',
    description: '',
    link: '',
    url: '',
    units: '',
    values: []
  })
}

const confirm = useConfirm()
const confirmDelete = (event, serie) => {
  confirm.require({
    target: event.currentTarget,
    message: '¿Seguro que quieres borrar esta serie?',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Cancelar',
    acceptLabel: 'Borrar',
    accept: () => {
      let index = indicador.value.series.indexOf(serie)
      if (index > -1) {
        indicador.value.series.splice(index, 1)
      }
    }
  })
}
</script>

<template>
  <ConfirmPopup class="max-w-30rem"></ConfirmPopup>

  <div v-if="indicador">
    <form ref="form" @submit.prevent="submit">
      <div class="formgrid grid">
        <div class="field col-12 lg:col-4">
          <label for="name">Nombre:</label>
          <InputText
            id="name"
            v-model="indicador.name"
            placeholder="Nombre corto"
            :invalid="v$.indicador.name.$error"
            :pt="{ root: { size: '10' } }"
          />
          <small id="name-help">Identificador corto</small>
          <span v-if="v$.indicador.name.$error" class="p-error">
            {{ v$.indicador.name.$errors[0].$message }}
          </span>
        </div>

        <div class="field col-12 lg:col-8">
          <label for="descripcion">Descripción:</label>
          <Textarea
            id="descripcion"
            v-model.trim="indicador.description"
            rows="4"
            cols="40"
            :invalid="v$.indicador.description.$error"
          />
          <span v-if="v$.indicador.description.$error" class="p-error">
            {{ v$.indicador.description.$errors[0].$message }}
          </span>
        </div>

        <div class="field col-12 md:col-6 max-w-full">
          <label for="provider">Proveedor:</label>
          <Dropdown
            id="provider"
            v-model="indicador.provider_uid"
            :options="providers"
            option-label="fullname"
            option-value="uid"
            placeholder="Selecciona un usuario"
            :invalid="v$.indicador.provider_uid.$error"
            class="w-full md:w-20rem"
          />
          <small id="name-help"
            >Usuario que actualizará los datos del indicador</small
          >
          <span v-if="v$.indicador.provider_uid.$error" class="p-error">
            {{ v$.indicador.provider_uid.$errors[0].$message }}
          </span>
        </div>

        <div class="field col">
          <label>Tipo:</label>
          <div class="flex align-items-center">
            <Checkbox
              v-model="indicador.estrategico"
              input-id="estrategico"
              :binary="true"
            />
            <label for="estrategico" class="ml-2"> Estratégico </label>
          </div>
        </div>

        <div v-if="indicador.estrategico" class="field col max-w-full">
          <label for="objetivo">Objetivo estratégico:</label>
          <Dropdown
            id="objetivo"
            v-model="indicador.objetivo_id"
            :options="indicador.estrategico ? objetivos : []"
            :option-label="(d) => d.name + ' - ' + d.description"
            option-value="id"
            placeholder="Selecciona un objetivo estratégico"
            :invalid="v$.indicador.objetivo_id.$error"
            class="max-w-full md:w-20rem"
          />
          <span v-if="v$.indicador.objetivo_id.$error" class="p-error">
            {{ v$.indicador.objetivo_id.$errors[0].$message }}
          </span>
        </div>

        <div class="field col-12 md:col-6 max-w-full">
          <label for="areas">Areas:</label>
          <MultiSelect
            id="areas"
            v-model="indicador.areas_id"
            :options="areas"
            option-label="name"
            option-value="id"
            display="chip"
            placeholder="Selecciona áreas"
            :invalid="v$.indicador.areas_id.$error"
            class="w-full md:w-20rem"
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>
                  {{ slotProps.option.name }} -
                  {{ slotProps.option.description }}
                </div>
              </div>
            </template>
          </MultiSelect>
          <span v-if="v$.indicador.areas_id.$error" class="p-error">
            {{ v$.indicador.areas_id.$errors[0].$message }}
          </span>
        </div>

        <div class="field col">
          <label for="base">Valor base:</label>
          <InputNumber
            id="base"
            v-model.number="indicador.base"
            locale="es-ES"
            :max-fraction-digits="3"
            :invalid="v$.indicador.base.$error"
          />
          <span v-if="v$.indicador.base.$error" class="p-error">
            {{ v$.indicador.base.$errors[0].$message }}
          </span>
        </div>

        <div class="field col">
          <label for="unidades">Unidades de medida:</label>
          <InputText
            id="unidades"
            v-model.number="indicador.units"
            type="text"
            :invalid="v$.indicador.units.$error"
          />
          <span v-if="v$.indicador.units.$error" class="p-error">
            {{ v$.indicador.units.$errors[0].$message }}
          </span>
        </div>

        <div class="field col">
          <label for="formula">Fórmula de cálculo:</label>
          <InputText
            id="formula"
            v-model.number="indicador.formula"
            type="text"
            :invalid="v$.indicador.formula.$error"
          />
          <span v-if="v$.indicador.formula.$error" class="p-error">
            {{ v$.indicador.formula.$errors[0].$message }}
          </span>
        </div>
      </div>

      <h3>Series de datos:</h3>
      <!-- <div v-for="(serie, idx) in indicador.series" :key="idx"> -->
      <!-- nested validation for series -->
      <div v-for="(serie, idx) in indicador.series" :key="idx">
        <IndicatorSerieUpdate
          v-model="indicador.series[idx]"
          @delete="confirmDelete($event, serie)"
        />
        <!-- :deletefunc="confirmDelete" -->
      </div>

      <Message v-if="v$.$errors.length > 0" severity="error" :closable="false">
        <p>Por favor revisa los siguientes errores en el formulario:</p>
        <ul>
          <li v-for="(error, idx) in v$.$errors" :key="idx">
            {{ error.$property }}: {{ error.$message }}
          </li>
        </ul>
      </Message>

      <div class="flex justify-content-end gap-2">
        <Button
          label="Añadir serie"
          severity="primary"
          outlined
          @click="addSerie"
        />
        <Button type="submit" label="Guardar" :loading="loading" />
        <!-- :disabled="v$.$error" -->
      </div>
    </form>
  </div>
  <div v-else>
    <p>Loading data...</p>
  </div>
</template>

<style scoped></style>
