<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs
} from '@vuelidate/validators'
import AuthService from '/src/services/AuthService.js'

const user = ref({
  username: '',
  email: '',
  lastname: '',
  firstname: '',
  password: ''
})
const password2 = ref('')
const router = useRouter()
const loading = ref(false)

async function loadData() {
  const response = await AuthService.getProfile()
  user.value = response.user
  user.value.password = ''
}
loadData()

// validation rules for Vuelidate
const rules = computed(() => ({
  user: {
    username: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(10),
      $autoDirty: true
    },
    firstname: {
      required,
      $autoDirty: true
    },
    lastname: {
      required,
      $autoDirty: true
    },
    email: {
      required,
      email,
      $autoDirty: true
    },
    password: {
      $autoDirty: true
    }
  },
  password2: {
    sameAs: sameAs(computed(() => user.value.password)),
    // using a custom validator instead
    // sameAs: helpers.withMessage('Debe ser igual', function(value) {
    //   console.log("checking", value, user.value.password)
    //   return value === user.value.password
    // }),
    $autoDirty: true
  }
}))
const v$ = useVuelidate(rules, { user, password2 })

const submit = async () => {
  const validForm = await v$.value.$validate()
  if (!validForm) {
    return
  }
  loading.value = true
  try {
    await AuthService.updateProfile(user.value)
    router.push({ name: 'UserList' })
  } catch {
    loading.value = false
  }
}
</script>

<template>
  <h1 class="my-4">Perfíl de usuario</h1>

  <form @submit.prevent="submit">
    <div class="formgrid grid">
      <div class="field col">
        <label for="name">Nombre de usuario:</label>
        <InputText
          id="username"
          v-model.trim="user.username"
          placeholder="Nombre corto"
          disabled
          :invalid="v$.user.username.$error"
        />
        <small>Nombre corto que identifica al usuario</small>
        <span v-if="v$.user.username.$error" class="p-error">
          {{ v$.user.username.$errors[0].$message }}
        </span>
      </div>

      <div class="field col">
        <label for="name">Nombre:</label>
        <InputText
          id="firstname"
          v-model.trim="user.firstname"
          placeholder="Nombre real"
          :invalid="v$.user.firstname.$error"
        />
        <small>Nombre real</small>
        <span v-if="v$.user.firstname.$error" class="p-error">
          {{ v$.user.firstname.$errors[0].$message }}
        </span>
      </div>

      <div class="field col">
        <label for="name">Apellidos:</label>
        <InputText
          id="lastname"
          v-model.trim="user.lastname"
          :invalid="v$.user.lastname.$error"
        />
        <span v-if="v$.user.lastname.$error" class="p-error">
          {{ v$.user.lastname.$errors[0].$message }}
        </span>
      </div>

      <div class="field col">
        <label for="name">Email:</label>
        <InputText
          id="email"
          v-model.trim="user.email"
          :invalid="v$.user.email.$error"
        />
        <span v-if="v$.user.email.$error" class="p-error">
          {{ v$.user.email.$errors[0].$message }}
        </span>
      </div>

      <div class="field col">
        <label for="name">Contraseña:</label>
        <Password
          id="password"
          v-model.trim="user.password"
          toggle-mask
          :invalid="v$.user.password.$error"
        />
        <small>Dejar en blanco para no modificar</small>
        <span v-if="v$.user.password.$error" class="p-error">
          {{ v$.user.password.$errors[0].$message }}
        </span>
      </div>

      <div class="field col">
        <label for="name">Contraseña (confirmar):</label>
        <Password
          id="password2"
          v-model.trim="password2"
          :feedback="false"
          toggle-mask
          :invalid="v$.password2.$error"
        />
        <small>Verificación de la contraseña</small>
        <span v-if="v$.password2.$error" class="p-error">
          {{ v$.password2.$errors[0].$message }}
        </span>
      </div>
    </div>

    <div class="col-sm-6 mt-3">
      <Button
        type="submit"
        label="Guardar"
        :loading="loading"
        :disabled="v$.$error"
      />
    </div>
  </form>
</template>

<style scoped></style>
