<script setup>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'
import ObjetivoService from '@/services/ObjetivoService.js'

const objetivo = ref({})
const route = useRoute()
const id = route.params.id
const router = useRouter()
const loading = ref(false)

const loadData = async () => {
  const resp = await ObjetivoService.get(id)
  objetivo.value = resp.objetivo
}
loadData()

// validation rules for Vuelidate
const shortName = helpers.regex(/^[a-zA-Z0-9-]*$/)
const rules = computed(() => ({
  objetivo: {
    name: {
      required,
      regex: helpers.withMessage('Caracteres no válidos', shortName),
      minLength: minLength(3),
      maxLength: maxLength(10)
    },
    description: {
      required,
      $autoDirty: true
    }
  }
}))

const v$ = useVuelidate(rules, { objetivo }, { $autoDirty: true })

const submit = async () => {
  const validForm = await v$.value.$validate()
  if (!validForm) {
    console.log('Invalid form')
    return
  }
  console.log('The form is valid. Sending data.')
  loading.value = true
  try {
    await ObjetivoService.update(objetivo.value)
    router.push({ name: 'ObjetivoList' })
  } catch {
    loading.value = false
  }
}
</script>

<template>
  <Card v-if="objetivo">
    <template #title>Objetivo {{ objetivo.name }}</template>
    <template #content>
      <form ref="form" @submit.prevent="submit">
        <div class="formgrid grid">
          <div class="field col">
            <label for="name">Nombre:</label>
            <InputText
              id="name"
              v-model.trim="objetivo.name"
              placeholder="Nombre corto"
              :invalid="v$.objetivo.name.$error"
            />
            <span v-if="v$.objetivo.name.$error" class="p-error">
              {{ v$.objetivo.name.$errors[0].$message }}
            </span>
          </div>

          <div class="field col">
            <label for="descripcion">Descripción:</label>
            <Textarea
              id="descripcion"
              v-model.trim="objetivo.description"
              :invalid="v$.objetivo.description.$error"
              rows="4"
              cols="40"
            />
            <span v-if="v$.objetivo.description.$error" class="p-error">
              {{ v$.objetivo.description.$errors[0].$message }}
            </span>
          </div>
        </div>
        <Button
          type="submit"
          label="Guardar"
          :loading="loading"
          :disabled="v$.$error"
        />
      </form>
    </template>
  </Card>
  <div v-else>
    <p>Cargando datos...</p>
    <ProgressSpinner />
  </div>
</template>
