import { api } from '/src/services/api.js'

export default {
  list() {
    return api('/series/')
  },

  create(serie) {
    return api(`/series/`, {
      method: 'POST',
      body: JSON.stringify({ serie })
    })
  },

  get(id) {
    return api(`/series/${id}/`)
  },

  get_name(name) {
    return api('/series/name/?' + new URLSearchParams({ name }))
  },

  update(serie) {
    return api(`/series/${serie.id}/`, {
      method: 'PUT',
      body: JSON.stringify({ serie })
    })
  },

  update_values(serie) {
    return api(`/series/${serie.id}/values`, {
      method: 'PUT',
      body: JSON.stringify({ values: serie.values })
    })
  },

  delete(serie) {
    return api(`/series/${serie.id}/`, {
      method: 'DELETE'
    })
  }
}
